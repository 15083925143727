import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import Layout from "../components/Layout/Layout";
import IMAGES from "../img/image";
import HowItWorks from "../components/Home/HowItWorks";
import SubscriberForm from "../components/Home/SubscriberForm";
import axios from "axios";
import CryptoJS from "crypto-js";
import "./ProductInfo.css";
import { useSelector } from "react-redux";

const ProductInfo = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [playerCheck, setPlayerCheck] = useState(null);
  const [product, setProduct] = useState(0);
  const [showImage, setShowImage] = useState(0);
  const [error, setError] = useState(false);
  const [cart, setCart] = useState(
    JSON.parse(localStorage.getItem("cart")) || []
  );
  const [goToCart, setGoToCart] = useState(false);

  const secretKey = "CHIGONG@#$123LEISTORE";
  const encryptPrice = (price) => {
    if (!secretKey) {
      console.error("Secret key is not defined");
      return price;
    }
    return CryptoJS.AES.encrypt(price.toString(), secretKey).toString();
  };

  //!NEW STATE
  const [amount, setAmount] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [productId, setProductId] = useState("");
  //! API BASED
  const [userId, setUserId] = useState("");
  const [zoneId, setZoneId] = useState("");
  const [notification, setNotification] = useState(false);

  function setPriceAndId(amount) {
    if (user?.reseller === "yes") {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.resPrice;
      setSelectedPrice(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
    } else {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.price;
      setSelectedPrice(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
    }
  }

  const getProduct = async () => {
    try {
      const res = await axios.post("/api/product/get-product-by-name", {
        name: params.name,
      });
      if (res.data.success) {
        if (user?.reseller === "yes") {
          const defaultAmount = res.data.data?.cost?.[0]?.amount;
          const defaultPrice = res.data.data?.cost?.[0]?.resPrice;
          const defaultId = res.data.data?.cost?.[0]?.id;
          setAmount(defaultAmount);
          setSelectedPrice(defaultPrice);
          setProductId(defaultId);
        } else {
          const defaultAmount = res.data.data?.cost?.[0]?.amount;
          const defaultPrice = res.data.data?.cost?.[0]?.price;
          const defaultId = res.data.data?.cost?.[0]?.id;
          setAmount(defaultAmount);
          setSelectedPrice(defaultPrice);
          setProductId(defaultId);
        }
        setProduct(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGoToCart = () => {
    if (cart.length > 0) {
      setGoToCart(true);
    }
  };

  useEffect(() => {
    handleGoToCart();
  }, [cart, product]);

  const handleAddToCart = () => {
    if (
      product?.apiName === "smileOne" ||
      product?.gameName === "Mobile Legends"
    ) {
      if (!playerCheck) {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 1500);
        return;
      }
      if (product === "") {
        return alert("Please Select Product");
      }
      if (productId === "") {
        return alert("Please Select Product");
      }
      if (userId === "") {
        return alert("Please Enter UserId");
      }
      if (product?.apiName === "smileOne" && zoneId === "") {
        return alert("Please Enter ZoneId");
      }
    } else if (product?.apiName === "yokcash") {
      if (product === "") {
        return alert("Please Select Product");
      }
      if (productId === "") {
        return alert("Please Select Product");
      }
      if (userId === "") {
        return alert("Please Enter UserId");
      }
      if (
        product?.apiName === "yokcash" &&
        (product?.gameName === "Honkai Star Rail" ||
          product?.gameName === "Genshin Impact") &&
        zoneId === ""
      ) {
        return alert("Please Select Server");
      }
    } else {
      if (userId === "") {
        return alert("Please Enter User Id");
      }
    }

    if (product?.api === "yes") {
    } else {
    }

    const itemToAdd = {
      id: product?._id,
      productId: productId,
      region: product?.region,
      api: product?.api,
      apiName: product?.apiName,
      gameName: product?.gameName,
      name: product?.name,
      price: encryptPrice(selectedPrice),
      images: product?.images,
      userId: userId.trim(),
      zoneId: zoneId.trim(),
      amount: amount,
      username: playerCheck,
    };
    const existingProductIndex = cart.findIndex(
      (item) =>
        item.id === itemToAdd.id &&
        item.selectedBrand === itemToAdd.selectedBrand &&
        item.selectedModel === itemToAdd.selectedModel
    );

    if (existingProductIndex !== -1) {
      setCart((prevCart) => {
        const updatedCart = [...prevCart];
        localStorage.setItem("cart", JSON.stringify(updatedCart));
        return updatedCart;
      });
    } else {
      setCart((prevCart) => {
        const updatedCart = [...prevCart, itemToAdd];
        localStorage.setItem("cart", JSON.stringify(updatedCart));
        return updatedCart;
      });
    }
    // Clear input fields
    setUserId("");
    setZoneId("");
    alert("Item added to cart!");
  };

  useEffect(() => {
    getProduct();
  }, []);

  const clearCart = () => {
    setCart([]);
    setGoToCart(!goToCart);
    localStorage.removeItem("cart");
    setUserId("");
    setZoneId("");
  };

  async function handleCheckPlayer() {
    if (userId === "" || zoneId === "") {
      return message.error(
        `${userId === "" ? "Enter user id" : "Enter zone id"}`
      );
    }
    try {
      setLoading(true);
      const idPro = productId.split("&")[0];
      const object = {
        userid: userId,
        zoneid: zoneId,
        apiName: product?.apiName,
      };
      const res = await axios.post("/api/payment/get-role", object);
      if (res.data.success) {
        setPlayerCheck(res.data.username);
        setLoading(false);
        setCart([]);
        localStorage.removeItem("cart");
        setGoToCart(false);
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setNotification(true);
    }, 2000);
  }, []);

  // useEffect(() => {
  //   if (cart?.length > 0) {
  //     alert("Product is already there in cart");
  //   }
  // }, [cart]);

  return (
    <Layout>
      <div className="product-info-container">
        {/* NOTIFICATION  */}
        {notification &&
          product?.notificationHeading?.split("=")[1] === "YES" && (
            <div className="notification-modal">
              <div className="notification">
                <h4>{product?.notificationHeading?.split("=")[0]}</h4>
                <p>{product?.notificationDescOne}</p>
                <p>{product?.notificationDescTwo}</p>
                <button onClick={() => setNotification(false)}>Close</button>
              </div>
            </div>
          )}

        <div className="d-none d-md-none d-lg-block product-info-img-slider">
          {product?.images?.map((item, index) => {
            return (
              <img
                onClick={() => setShowImage(index)}
                key={index}
                src={`https://chigongleistore.com/${item}`}
                alt=""
              />
            );
          })}
        </div>
        <div className="product-info-img">
          {product?.images && product?.images.length > 0 && (
            <img
              src={`https://chigongleistore.com/${product?.images[showImage]}`}
              alt=""
            />
          )}
        </div>
        <div className="d-block d-lg-none product-info-img-slider">
          {product?.images?.map((item, index) => {
            return (
              <img
                key={index}
                onClick={() => setShowImage(index)}
                src={`https://chigongleistore.com/${item}`}
                alt="loading.."
              />
            );
          })}
        </div>
        <div className="product-info-content mb-2">
          <h3 className="">{product?.name}</h3>
          <span className="">Instant Delivery Supported ⚡</span>
          <span>{product?.desc}</span>
          <div className="price ">
            {selectedPrice !== null ? (
              <h3 className="m-0 mt-3">
                <b>Rs. {selectedPrice}</b>
              </h3>
            ) : (
              "Select an amount to see the price"
            )}
          </div>
          <div className="p-amount w-100">
            {product?.cost?.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    setAmount(item.amount);
                    setPriceAndId(item.amount);
                  }}
                  key={index}
                  className={`py-3 amount ${
                    amount === item?.amount && "active"
                  }`}
                >
                  <span>
                    <small>{item.amount}</small>
                  </span>
                </div>
              );
            })}
          </div>
          {product?.apiName === "smileOne" ? (
            <>
              <div className="d-flex align-items-center">
                <input
                  className="player-tag"
                  type="text"
                  name="userId"
                  placeholder="User ID"
                  onChange={(e) => setUserId(e.target.value)}
                  value={userId}
                />
                <button className="p-check-btn" onClick={handleCheckPlayer}>
                  Check
                  {loading && (
                    <div class="spinner-grow spinner-grow-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
              <input
                className="player-tag"
                type="text"
                name="zoneid"
                placeholder="(   ZONE ID   )"
                onChange={(e) => setZoneId(e.target.value)}
                value={zoneId}
              />
              <span className="text-danger">
                {error && "First Check your username by clicking check button"}
              </span>
              <span className="text-success">
                {playerCheck && "Username: " + playerCheck}
              </span>
            </>
          ) : product?.apiName === "yokcash" &&
            (product?.gameName === "MLBB" ||
              product?.gameName === "Mobile Legends") ? (
            <>
              <div className="d-flex align-items-center">
                <input
                  className="player-tag"
                  type="text"
                  name="userId"
                  placeholder="User ID"
                  onChange={(e) => setUserId(e.target.value)}
                  value={userId}
                />
                <button className="p-check-btn" onClick={handleCheckPlayer}>
                  Check
                  {loading && (
                    <div class="spinner-grow spinner-grow-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
              <input
                className="player-tag"
                type="text"
                name="zoneid"
                placeholder="(   Zone ID   )"
                onChange={(e) => setZoneId(e.target.value)}
                value={zoneId}
              />
              <span className="text-danger">
                {error && "First Check your username by clicking check button"}
              </span>
              <span className="text-success">
                {playerCheck && "Username: " + playerCheck}
              </span>
            </>
          ) : product?.apiName === "yokcash" &&
            (product?.gameName === "Genshin Impact" ||
              product?.gameName === "Honkai Star Rail") ? (
            <>
              <div className="d-flex align-items-center">
                <input
                  className="player-tag"
                  type="text"
                  name="userId"
                  placeholder="User ID"
                  onChange={(e) => setUserId(e.target.value)}
                  value={userId}
                />
              </div>
              <select
                name="zoneid"
                className="form-select"
                onChange={(e) => setZoneId(e.target.value)}
              >
                <option value="">Select Server</option>
                <option value="America">America</option>
                <option value="Asia">Asia</option>
                <option value="Europe">Europe</option>
                <option value="TW_HK_MO">TW_HK_MO</option>
              </select>
              <span className="text-danger">
                {error && "First Check your username by clicking check button"}
              </span>
            </>
          ) : product?.apiName === "yokcash" &&
            (product?.gameName === "Free Fire" ||
              product?.gameName === "Call Of Duty Mobile" ||
              product?.gameName === "PUBG Mobile" ||
              product?.gameName === "Valorant" ||
              product?.gameName === "Clash Of Clans" ||
              product?.gameName === "League of Legends Wild Rift") ? (
            <input
              className="player-tag"
              type="text"
              name="userId"
              placeholder={`${product?.tag}`}
              onChange={(e) => setUserId(e.target.value)}
              value={userId}
            />
          ) : product?.fields === "2" ? (
            <>
              <input
                className="player-tag"
                type="text"
                name="userId"
                placeholder={`${product?.tagOne}`}
                onChange={(e) => setUserId(e.target.value)}
                value={userId}
              />
              <input
                className="player-tag"
                type="text"
                name="zoneId"
                placeholder={`${product?.tagTwo}`}
                onChange={(e) => setZoneId(e.target.value)}
                value={zoneId}
              />
            </>
          ) : (
            <input
              className="player-tag"
              type="text"
              name="userId"
              placeholder={`${product?.tagOne}`}
              onChange={(e) => setUserId(e.target.value)}
              value={userId}
            />
          )}
          {product?.stock === "no" ? (
            <button className="add-to-cart-btn" style={{ opacity: "0.7" }}>
              Out of Stock
            </button>
          ) : goToCart ? (
            <>
              <button
                onClick={() => navigate("/cart")}
                className="add-to-cart-btn"
              >
                Go To Cart
              </button>
              <button
                onClick={clearCart}
                className="add-to-cart-btn bg-dark text-white"
              >
                Clear Cart
              </button>
            </>
          ) : (
            <button onClick={handleAddToCart} className="add-to-cart-btn">
              Add To Cart
            </button>
          )}
          <div className="safe-checkout col-12 col-lg-6">
            <span>Guaranteed Safe Checkout</span>
            <img className="safe-checkout-img" src={IMAGES.pgu} alt="" />
          </div>
        </div>
      </div>
      <div className="product-desc">
        <h5>Description</h5>
        <p>{product?.descTwo}</p>
      </div>
    </Layout>
  );
};

export default ProductInfo;
