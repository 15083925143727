import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import "./Checkout.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { message } from "antd";
import { setUser } from "../redux/features/userSlice";
import CheckIcon from "@mui/icons-material/Check";
import CryptoJS from "crypto-js";
import getUserData from "../utils/userDataService.js";

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const { discount } = useSelector((state) => state.discount);
  const [cart, setCart] = useState(
    JSON.parse(localStorage.getItem("cart")) || []
  );
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [mode, setMode] = useState("upi");
  const [processingOrder, setProcessingOrder] = useState(false); // Flag for processing order
  const [balance, setBalance] = useState("");

  useEffect(() => {
    getUserData(dispatch, setUser, setBalance);
  }, []);

  const calculateTotal = () => {
    const newTotal = cart.reduce(
      (acc, item) => acc + parseInt(decryptPrice(item.price)),
      0
    );
    setTotal(newTotal);
  };

  const generateOrderId = () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    const randomNum = Math.floor(1000 + Math.random() * 9000); // Ensures a 4-digit number
    const orderId = `${year}${month}${day}${hours}${minutes}${seconds}${randomNum}`;
    setOrderId(orderId);
  };

  const secretKey = "CHIGONG@#$123LEISTORE";
  const decryptPrice = (encryptedPrice) => {
    if (!secretKey) {
      console.error("Secret key is not defined");
      return encryptedPrice;
    }
    const bytes = CryptoJS.AES.decrypt(encryptedPrice, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  useEffect(() => {
    calculateTotal();
  }, [cart]);

  useEffect(() => {
    generateOrderId();
  }, []);

  function checkPlaceOrder(e) {
    if (cart[0]?.api === "yes") {
      if (cart[0]?.apiName === "yokcash") {
        if (mode === "upi") {
          handleYokcashUpiOrder(e);
        } else {
          handleYokcashWalletOrder(e);
        }
      } else {
        if (mode === "upi") {
          handleSmileOneUpiOrder(e);
        } else {
          handleSmileOneWalletOrder(e);
        }
      }
    } else {
      if (mode === "upi") {
        handleUpiOrder(e);
      } else {
        handleWalletOrder(e);
      }
    }
  }

  // yokcash
  async function handleYokcashUpiOrder(e) {
    e.preventDefault();
    try {
      setLoading(true);
      const paymentObject = {
        order_id: orderId,
        txn_amount: decryptPrice(cart[0]?.price),
        product_name: cart[0]?.amount,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://chigongleistore.com/api/yokcash/status?orderId=${orderId}`,
        txn_note:
          cart[0].userId +
          "@" +
          cart[0]?.zoneId +
          "@" +
          cart[0]?.productId +
          "@" +
          cart[0]?.name +
          "@" +
          cart[0].amount,
      };

      const response = await axios.post("/api/yokcash/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        localStorage.removeItem("cart");
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  async function handleYokcashWalletOrder(e) {
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: decryptPrice(cart[0]?.price),
        product_name: cart[0]?.amount,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        txn_note:
          cart[0].userId +
          "@" +
          cart[0]?.zoneId +
          "@" +
          cart[0]?.productId +
          "@" +
          cart[0]?.name +
          "@" +
          cart[0].amount,
      };
      setLoading(true);
      const res = await axios.post("/api/yokcash/wallet", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        localStorage.removeItem("cart");
        localStorage.setItem("orderProcess", "no");
        setOrderSuccess(true);
        navigate("/");
      } else {
        setLoading(false);
        message.error(res.data.message);
        localStorage.setItem("orderProcess", "no");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  // smile
  const handleSmileOneUpiOrder = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const paymentObject = {
        order_id: orderId,
        txn_amount: decryptPrice(cart[0]?.price),
        product_name: cart[0]?.region,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://chigongleistore.com/api/smile/status?orderId=${orderId}`,
        txn_note:
          cart[0].userId +
          "@" +
          cart[0]?.zoneId +
          "@" +
          cart[0]?.productId +
          "@" +
          cart[0]?.name +
          "@" +
          cart[0].amount,
      };
      const response = await axios.post("/api/smile/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        localStorage.removeItem("cart");
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleSmileOneWalletOrder = async (e) => {
    e.preventDefault();
    try {
      const orderObject = {
        orderId: orderId,
        userid: cart[0]?.userId,
        zoneid: cart[0]?.zoneId,
        productid: cart[0]?.productId,
        region: cart[0]?.region,
        customer_email: user && user?.email,
        customer_mobile: user && user?.mobile,
        pname: cart[0]?.name,
        amount: cart[0]?.amount,
        price: decryptPrice(cart[0]?.price),
      };
      setLoading(true);
      const res = await axios.post("/api/smile/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        localStorage.removeItem("cart");
        localStorage.setItem("orderProcess", "no");
        setOrderSuccess(true);
        navigate("/");
      } else {
        setLoading(false);
        message.error(res.data.message);
        localStorage.setItem("orderProcess", "no");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // manual
  const handleUpiOrder = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const paymentObject = {
        order_id: orderId,
        txn_amount: decryptPrice(cart[0]?.price),
        product_name: cart[0]?.name,
        customer_name: user?.fname,
        customer_mobile: user?.mobile,
        customer_email: user?.email,
        callback_url: `https://chigongleistore.com/api/manual/status?orderId=${orderId}`,
        txn_note: cart[0].userId + "@" + cart[0]?.amount,
      };
      const response = await axios.post("/api/manual/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        localStorage.removeItem("cart");
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleWalletOrder = async (e) => {
    e.preventDefault();
    if (loading) {
      return message.error("Prev order in process");
    }
    try {
      setLoading(true);
      const orderObject = {
        api: "no",
        client_txn_id: orderId,
        userid: cart[0].userId,
        zoneid: cart[0]?.zoneId,
        productid: cart[0]?.productId,
        region: cart[0]?.region,
        customer_email: user && user?.email,
        customer_mobile: user && user?.mobile,
        pname: cart[0]?.name,
        amount: cart[0]?.amount,
        price: decryptPrice(cart[0]?.price),
      };
      const res = await axios.post("/api/manual/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        localStorage.removeItem("cart");
        localStorage.setItem("orderProcess", "no");
        navigate("/");
      } else {
        message.error(res.data.message);
        setLoading(false);
        localStorage.setItem("orderProcess", "no");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      localStorage.setItem("orderProcess", "no");
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (processingOrder) {
        const message =
          "Don't refresh the page, Otherwise you will lost the funds";
        event.preventDefault();
        event.returnValue = message;
        return message; // For older versions of Firefox
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [processingOrder]);

  useEffect(() => {
    if (!localStorage.getItem("orderProcess")) {
      localStorage.setItem("orderProcess", "no");
    }
  }, []);

  return (
    <Layout>
      {orderSuccess ? (
        <div className="order-success-container">
          <CheckIcon className="icon" />
          <span className="text-muted">Hey! {user?.fname}</span>
          <h4 className="my-1">Thank you for ordering!</h4>
          <span className="text-muted text-center">
            We have received your order and it will completed instantly!
          </span>
          <button
            onClick={() => {
              navigate("/user-dashboard");
              setOrderSuccess(false);
            }}
            className="add-to-cart-btn"
          >
            Check Order Status
          </button>
        </div>
      ) : loading ? (
        <div className="order-placing-loader">
          <div class="me-2 spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <br />
          Do not refresh or take back Otherwise the funds will be lost.
          <br />
          Order Processing!
        </div>
      ) : (
        <div className="container checkout-container">
          <div className="customer-form">
            {!user && (
              <h5>
                Already a customer? <Link to="/login">Login</Link>
              </h5>
            )}
            {user && (
              <div>
                <h5>Account Details</h5>
                <div className="row">
                  <div className="mb-3 col-12 col-sm-12 col-md-6 col-lg-6">
                    <label className="form-label" htmlFor="">
                      Name :
                    </label>
                    <h5>{user && user?.fname}</h5>
                  </div>
                  <div className="mb-3 col-12 col-sm-12 col-md-6 col-lg-6">
                    <label className="form-label" htmlFor="">
                      Email :
                    </label>
                    <h5>{user && user?.email}</h5>
                  </div>
                  <div className="mb-3 col-12 col-sm-12 col-md-6 col-lg-6">
                    <label className="form-label" htmlFor="">
                      Mobile :
                    </label>
                    <h5>{user && user?.mobile}</h5>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* CART  */}
          <div className="checkout-product-details">
            <div className="checkout-item-container">
              <div className="d-flex justify-content-between">
                <span>Product</span>
                <span>Subtotal</span>
              </div>
              <hr />
              {cart &&
                cart.map((item, index) => {
                  return (
                    <div key={index} className="checkout-item">
                      <span>{item.name}</span>
                      <span>{decryptPrice(item.price)}</span>
                    </div>
                  );
                })}
              <hr />
              <div className="checkout-item">
                <span>
                  <b>Wallet Balance</b>
                </span>
                <span>
                  <b>{user ? balance : <Link to="/login">Login</Link>}</b>
                </span>
              </div>
              <div className="checkout-item">
                <span>
                  <b>Total</b>
                </span>
                <span>
                  <b>Rs. {total}</b>
                </span>
              </div>
              <div className="checkout-item">
                <span>
                  <b>Payment Mode</b>
                </span>
                <div className="d-flex gap-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="paymentMode"
                      id="upiRadio"
                      value="upi"
                      checked={mode === "upi"}
                      onChange={() => setMode("upi")}
                    />
                    <label className="form-check-label" htmlFor="upiRadio">
                      UPI
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="paymentMode"
                      id="walletRadio"
                      value="wallet"
                      checked={mode === "wallet"}
                      onChange={() => setMode("wallet")}
                    />
                    <label className="form-check-label" htmlFor="walletRadio">
                      Wallet
                    </label>
                  </div>
                </div>
              </div>
              {balance >= total && mode === "wallet" && (
                <div className="checkout-item">
                  <span>Balance after order</span>
                  <span>
                    <b>Rs. {balance - total}</b>
                  </span>
                </div>
              )}
            </div>
            {user && mode === "upi" ? (
              <button
                disabled={loading}
                onClick={checkPlaceOrder}
                className="w-100 add-to-cart-btn"
              >
                Place Order
                {loading && (
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            ) : balance >= total ? (
              <button
                disabled={loading}
                onClick={checkPlaceOrder}
                className="w-100 add-to-cart-btn"
              >
                Place Order
              </button>
            ) : balance < total ? (
              <button
                onClick={() => navigate("/wallet")}
                className="w-100 add-to-cart-btn"
              >
                Topup your wallet
              </button>
            ) : (
              <button
                onClick={() => navigate("/login")}
                className="w-100 add-to-cart-btn"
              >
                Login to Continue
              </button>
            )}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Checkout;
