import React from "react";
import "./Maintenance.css";

const Maintenance = () => {
  return (
    <div className="maintenance-container">
      <div className="terminal-loader">
        <div className="terminal-header">
          <div className="terminal-title">Status</div>
          <div className="terminal-controls">
            <div className="control close"></div>
            <div className="control minimize"></div>
            <div className="control maximize"></div>
          </div>
        </div>
        <div className="content">
          <div className="text">Website Under Maintenance..</div>
        </div>
      </div>
      <button
        className="btn btn-success"
        onClick={() => window.open("https://wa.me/919862622104")}
      >
        Contact To Admin
      </button>
    </div>
  );
};

export default Maintenance;
